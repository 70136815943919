.todo-box {
  margin-top: 0.5rem;
  min-width: 32rem;
  /* max-height: 50vh;
  overflow: auto; */
}
.todo-box.dashboard-todo {
  min-width: 0;
  max-width: none;
}
.todo-list-wrapper {
  min-width: 32rem;
  padding: 0.5rem 0rem;
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.todo-list-wrapper.dashboard-todo {
  min-width: 0;
  max-width: none;
}
.todo-list {
  margin: 0.35rem 0;
  border-radius: 0.35rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.5rem;
  color: #fff;
}
.rounded-cont {
  border-radius: 3rem;
}
.task-container {
  max-height: 15rem;
  overflow: auto;
}
.todo-task,
.todo-task-non-active {
  font-size: 1.4rem;
  width: 80%;
  position: relative;
  transition: 0.3s ease;
}
.dd-icon::before {
  margin-right: 0.5rem;
  content: "\f0c9";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: small;
  color: #fff;
  vertical-align: middle;
  cursor: grab;
}
.todo-actions {
  display: flex;
  justify-content: flex-end;
  width: 20%;
  font-size: 1rem;
}
.todo-actions button {
  background: transparent !important;
  backdrop-filter: none;
}
.todo-actions .success-btn:hover {
  color: yellowgreen;
}
.todo-actions .danger-btn:hover {
  color: tomato;
}
@media only screen and (max-width: 800px) {
  .todo-list-wrapper,
  .todo-box {
    min-width: 18rem;
    width: 100%;
    max-width: 26rem;
    padding: 0 0.5rem;
  }
  .todo-task {
    font-size: 1.2rem;
  }
  .todo-task-non-active {
    font-size: 1.2rem;
  }
  .todo-actions {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 340px) {
  .todo-list-wrapper,
  .todo-box {
    min-width: 0;
    width: 100%;
    padding: 0 0.5rem;
  }
  .todo-task {
    font-size: 1rem;
  }
  .todo-task-non-active {
    font-size: 1rem;
  }
  .todo-actions {
    font-size: 0.6rem;
  }
}
