@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap");
.timer-container {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  --counter-width: 30rem;
  background-color: rgba(0, 0, 0, 0.4);
  /* overflow: hidden; */
}
@media only screen and (min-width: 800px) {
  .timer-container.active {
    padding-top: 2rem;
    width: 30vw;
    min-width: 35rem;
  }
}
.timer-box {
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;
  min-width: 32rem;
  min-height: 21.5rem;
  position: relative;
}
.timer-box.active {
  min-height: 17rem;
}
@media only screen and (max-width: 800px) {
  .timer-box {
    min-width: 0px !important;
    min-height: 0px !important;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 0;
    backdrop-filter: none !important;
    padding: 0;
  }
}
.tab-list {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;
  margin-top: 0.5rem;
  background: rgba(255, 255, 255, 0.4);
}
.tab-list-item {
  padding: 0 0.8rem;
  cursor: pointer;
  transition: 0.3s ease;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab-list-item.active {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 1rem;
}
.tab-list-item.right::after {
  margin-left: 0.25rem;
  color: #000;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f054";
  font-size: small;
}
.tab-list-item.left::before {
  margin-right: 0.25rem;
  color: #000;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f053";
  font-size: small;
}
.counter {
  position: absolute;
  min-width: var(--counter-width);
  padding: 0 3rem 1.5rem 3rem;
}
.count {
  font-family: "Orbitron", sans-serif;
  font-size: 5rem;
  margin: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}
@media only screen and (max-width: 350px) {
  .count {
    font-size: 4rem !important;
  }
  .count::after {
    content: attr(name);
    font-size: 2rem;
  }
}
@media only screen and (max-width: 310px) {
  .count {
    font-size: 3.5rem !important;
  }
  .count::after {
    content: attr(name);
    font-size: 1.8rem;
  }
}
@media only screen and (max-width: 250px) {
  .count {
    font-size: 3rem !important;
  }
  .count::after {
    content: attr(name);
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 220px) {
  .count {
    font-size: 2.5rem !important;
  }
  .count::after {
    content: attr(name);
    font-size: 1rem;
  }
}
.button-container {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-evenly;
}
.login-cont {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-evenly;
}
.user-cont {
  margin-top: 0.5rem;
  padding: 0 0.5rem;
}
.notes-btn {
  position: absolute;
  left: 0;
  bottom: 1.5rem;
  display: flex;
  border-radius: 50% !important;
  justify-content: center;
  align-items: center;
}
