.notepad-drawer {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 30vw;
  transition: 0.3s ease;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.notepad-title {
  color: #fff;
  font-size: 1.2rem;
  height: 5%;
}
.notepad-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.notepad-wrapper {
  z-index: 120;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  margin: 0.5rem 0;
  height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.notepad-drawer.close {
  transform: translateX(100%);
}
.notepad {
  border-radius: 0.5rem;
  background-color: #fff;
  height: 75%;
}
.notepad.quill {
  height: 100%;
}
.loading-text {
  font-size: small;
  margin-left: 0.5rem;
}
.idea-thought-input {
  width: 100% !important;
  height: 100% !important;
}
.idea-thought-input textarea:focus-visible {
  outline: none !important;
}
#idea-thought-textarea {
  height: 100% !important;
}
