.bg-card-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.bg-card {
  width: 25%;
  padding: 0.25rem;
}
.bg-card.active {
  border: 2px solid #fff;
  border-radius: 0.5rem;
}
.bg-img {
  width: 100%;
  height: 4.5rem;
  border-radius: 0.5rem;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0.35rem;
}
.bg-img i {
  color: #fff;
  font-size: small;
}
.audio-wrapper {
  margin-top: 0.75rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  border-radius: 0.5rem;
  background: rgb(51, 51, 51);
}
.audio-icon {
  width: 5%;
  color: #fff;
  cursor: pointer;
}
.audio-slider {
  width: 75%;
}
.audio-test {
  width: 10%;
}
@media only screen and (max-width: 800px) {
  .audio-slider {
    width: 70%;
  }
  .audio-test {
    width: 15%;
  }
}
.css-187mznn-MuiSlider-root {
  color: #fff !important;
}
