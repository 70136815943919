.App {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  background-size: cover !important;
}
* {
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
*::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.color-white {
  color: #fff !important;
}
