.notepad-drawer .MuiPaper-root {
  background: transparent;
  height: 50vh !important;
  overflow: scroll;
}
.notepad-title {
  color: #fff;
  font-size: 1.2rem;
  height: 5%;
}
.notepad-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.notepad-wrapper-mobile {
  z-index: 120;
  padding: 0.5rem 0.5rem;
  margin: 0;
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 0 0 0.5rem 0.5rem;
}
.notepad-drawer.close {
  transform: translateX(100%);
}
.notepad-mobile {
  border-radius: 0.5rem;
  background-color: #fff;
  margin-top: 1rem;
  height: 95%;
  overflow: hidden;
}
.notepad.quill {
  height: 100%;
}
.loading-text {
  font-size: small;
  margin-left: 0.5rem;
}
.idea-thought-input {
  width: 100% !important;
  height: 100% !important;
}
.idea-thought-input textarea {
  margin: 0;
}
.idea-thought-input textarea:focus-visible {
  outline: none !important;
}
#idea-thought-textarea {
  height: 100% !important;
}
