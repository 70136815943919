.dashboard {
  background-color: rgb(22, 28, 36);
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
}
.dashbaord-content {
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}
.breadcrumbs-container {
  padding: 1rem 2rem;
  height: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.breadcrumbs-container a {
  color: rgba(255, 255, 255, 0.8) !important;
  text-decoration: none !important;
  transition: all 0.3s ease-in-out !important;
}
.neomorph {
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.1);
}
.rotate.neomorph:hover {
  box-shadow: 8px -8px 12px 0 rgba(255, 255, 255, 0.1),
    -12px 12px 16px 0 rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 800px) {
  .breadcrumbs-container * {
    font-size: 0.8rem !important;
  }
  .neomorph {
    box-shadow: none;
  }
}
.breadcrumbs-container a:hover {
  color: #fff !important;
}
.MuiBreadcrumbs-separator {
  color: rgba(255, 255, 255, 0.5);
}
.breadcrumbs-last {
  color: #fff !important;
  user-select: none !important;
}
