.setting-drawer .MuiPaper-root {
  max-height: 70vh;
  overflow: auto;
  background: transparent !important;
  height: 100%;
  display: flex;
  align-items: center;
  max-width: 100vw !important;
}
.setting-box-mobile {
  height: 100%;
  padding: 0.5rem 0.25rem;
  overflow: auto;
  border-radius: 0.5rem 0.5rem 0 0;
}
.timer-input-group-mobile {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.timer-input-mobile {
  width: 50%;
  transition: 0.3s ease;
  overflow: hidden;
}
