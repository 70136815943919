.MuiDialog-paper {
  background-color: transparent !important;
  border-radius: 0.75rem !important;
}
.task-dialog {
  min-width: 32rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
}
@media only screen and (max-width: 800px) {
  .task-dialog {
    min-width: 19rem;
  }
}
@media only screen and (max-width: 320px) {
  .task-dialog {
    min-width: 100%;
  }
}
#task-title {
  color: #fff;
  font-size: 1.5rem;
  padding: 0.25rem 0.5rem;
}
.text-left {
  text-align: left !important;
}
.MuiAutocomplete-root {
  width: 100% !important;
}
.MuiInput-root {
  color: #fff !important;
  height: 100% !important;
}
.MuiInput-root::before {
  border-bottom: none !important;
}
.MuiInput-root::after {
  border-bottom: none !important;
}
