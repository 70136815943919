@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  background-size: cover !important;
}
* {
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
*::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.color-white {
  color: #fff !important;
}

.TimerApp {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  background-size: cover !important;
}
* {
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
*::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.timer-container {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  --counter-width: 30rem;
  background-color: rgba(0, 0, 0, 0.4);
  /* overflow: hidden; */
}
@media only screen and (min-width: 800px) {
  .timer-container.active {
    padding-top: 2rem;
    width: 30vw;
    min-width: 35rem;
  }
}
.timer-box {
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;
  min-width: 32rem;
  min-height: 21.5rem;
  position: relative;
}
.timer-box.active {
  min-height: 17rem;
}
@media only screen and (max-width: 800px) {
  .timer-box {
    min-width: 0px !important;
    min-height: 0px !important;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 0;
    -webkit-backdrop-filter: none !important;
            backdrop-filter: none !important;
    padding: 0;
  }
}
.tab-list {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;
  margin-top: 0.5rem;
  background: rgba(255, 255, 255, 0.4);
}
.tab-list-item {
  padding: 0 0.8rem;
  cursor: pointer;
  transition: 0.3s ease;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab-list-item.active {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 1rem;
}
.tab-list-item.right::after {
  margin-left: 0.25rem;
  color: #000;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f054";
  font-size: small;
}
.tab-list-item.left::before {
  margin-right: 0.25rem;
  color: #000;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f053";
  font-size: small;
}
.counter {
  position: absolute;
  min-width: var(--counter-width);
  padding: 0 3rem 1.5rem 3rem;
}
.count {
  font-family: "Orbitron", sans-serif;
  font-size: 5rem;
  margin: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}
@media only screen and (max-width: 350px) {
  .count {
    font-size: 4rem !important;
  }
  .count::after {
    content: attr(name);
    font-size: 2rem;
  }
}
@media only screen and (max-width: 310px) {
  .count {
    font-size: 3.5rem !important;
  }
  .count::after {
    content: attr(name);
    font-size: 1.8rem;
  }
}
@media only screen and (max-width: 250px) {
  .count {
    font-size: 3rem !important;
  }
  .count::after {
    content: attr(name);
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 220px) {
  .count {
    font-size: 2.5rem !important;
  }
  .count::after {
    content: attr(name);
    font-size: 1rem;
  }
}
.button-container {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-evenly;
}
.login-cont {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-evenly;
}
.user-cont {
  margin-top: 0.5rem;
  padding: 0 0.5rem;
}
.notes-btn {
  position: absolute;
  left: 0;
  bottom: 1.5rem;
  display: flex;
  border-radius: 50% !important;
  justify-content: center;
  align-items: center;
}

.mobile-timer-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}
.mobile-tabs-wrapper {
  width: 100%;
  padding: 1rem 0;
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 0 0 0.5rem 0.5rem;
}
.mobile-tab-list {
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.2rem 0.5rem;
  width: 100%;
  color: #fff;
  vertical-align: bottom;
}
.mobile-tab-list-item {
  font-size: 1rem;
  font-weight: 400;
  transition: 0.3s ease;
}
.mobile-tab-list-item.active {
  font-size: 1.5rem;
  font-weight: 600;
}
.mobile-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem 0.5rem 0 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.mobile-notes-btn {
  position: absolute;
  left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-setting-btn {
  position: absolute;
  right: 0rem;
}
.user-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}
.mobile-user-img {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}
.mobile-button-container {
  min-width: 20rem;
  max-width: 30rem;
  width: 100%;
  padding: 0 0.5rem;
  display: flex;
  justify-content: space-evenly;
}
@media only screen and (max-width: 310px) {
  .mobile-button-container {
    min-width: 0;
  }
}
.mobile-action-drawer .MuiPaper-root {
  background: transparent !important;
  max-width: 100vw !important;
  border-radius: 0.5rem 0.5rem 0 0 !important;
}
.mobile-user-drawer {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem 0.5rem;
  padding: 0.5rem;
}
.user-action {
  padding: 0.25rem 0;
}
.mobile-logout-btn {
  padding: 0.8rem 0 !important;
  font-size: 0.8rem !important;
}
.mobile-dashboard-btn {
  padding: 0.25rem 0 1rem 0 !important;
  font-size: 0.8rem !important;
}
.mobile-count-box {
  padding: 0 0.8rem;
  max-width: 100vw;
}

.blur-box {
  background-color: rgba(0, 0, 0, 0.7) !important;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .blur-box {
    background-color: rgba(255, 255, 255, 0.15) !important;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
}

.button {
  background-color: rgba(255, 255, 255, 0.562) !important;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  padding: 1rem 0.5rem;
  border-radius: 0.4rem;
  box-shadow: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  transition: 0.3s ease;
}
.button:disabled {
  cursor: not-allowed;
}
.button.bg-green {
  background-color: rgba(203, 238, 147, 0.9) !important;
}
.button.bg-red {
  background-color: rgba(238, 147, 147, 0.9) !important;
}
.button.bg-green:hover:not(:disabled) {
  background-color: rgb(176, 211, 119) !important;
}
.button.bg-red:hover:not(:disabled) {
  background-color: rgb(228, 120, 120) !important;
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .button {
    background-color: rgba(255, 255, 255, 0.15) !important;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
  .button.bg-green {
    background-color: rgba(203, 238, 147, 0.5) !important;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
  .button.bg-red {
    background-color: rgba(238, 147, 147, 0.5) !important;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
}
.btn-small {
  font-size: 0.75rem;
  text-align: center;
  padding: 0.5rem;
}
.width-100 {
  width: 100%;
}
.transparent {
  background: transparent !important;
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}
.success-btn:hover {
  color: yellowgreen;
}
.danger-btn:hover {
  color: tomato;
}
.rounded-btn {
  border-radius: 50% !important;
  width: 2.5rem;
  height: 2.5rem;
}

.user-card {
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0.5rem;
}
.user-img-cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 25%;
}
.user-img-cont div.user-img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}
.user-img-cont .user-img {
  border-radius: 50%;
  width: 70%;
  height: auto;
}
.user-meta {
  width: 65%;
  padding-left: 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.user-option {
  position: relative;
  width: 3rem;
  height: 3rem;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 100%;
  transition: all 0.3s;
  cursor: pointer;
}
.user-option.disable {
  cursor: not-allowed;
  opacity: 0.5;
}
.user-option.active:hover {
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
}
.setting-btn,
.logout-btn,
.dashboard-btn,
.timer-btn,
.dashboard-logout-btn {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
}
.setting-btn *,
.logout-btn *,
.dashboard-btn * {
  font-size: 1rem;
}
.timer-btn {
  height: 3rem !important;
  border-radius: 50%;
}
.dashboard-btn {
  height: 3rem !important;
  border-radius: 50%;
}
.setting-btn {
  height: 3rem !important;
  border-radius: 50%;
}
.logout-btn,
.dashboard-logout-btn {
  height: 3rem !important;
  border-radius: 3rem;
}
.user-option.active:hover > i {
  transform: rotateZ(-180deg);
}
.user-option.active:hover .timer-btn {
  opacity: 1;
  left: 3.5rem;
  width: 3rem !important;
}
.user-option.active:hover .dashboard-logout-btn {
  opacity: 1;
  left: 7rem;
  width: 3rem !important;
}
.user-option.active:hover .dashboard-btn {
  opacity: 1;
  left: 3.5rem;
  width: 3rem !important;
}
.user-option.active:hover .setting-btn {
  opacity: 1;
  left: 7rem;
  width: 3rem !important;
}
.user-option.active:hover .logout-btn {
  opacity: 1;
  left: 10.5rem;
  width: 8rem !important;
}
.user-option.active > i {
  transition: 0.3s ease;
}
.setting-btn i {
  transition: 0.5s ease;
}
.user-option.active:hover .setting-btn i {
  transform: rotateZ(180deg);
}
.user-name {
  color: #fff;
  font-size: 1.5rem;
  text-transform: capitalize;
}
.dashboard-user-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.user-img-container {
  width: 100%;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-img-container img,
.user-img-container .MuiAvatar-root {
  height: 6rem !important;
  width: 6rem;
}
.user-img-container .MuiAvatar-root {
  font-size: 3rem;
}
.user-name-container {
  width: 100%;
  padding: 0.2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-actions {
  padding-bottom: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.todo-box {
  margin-top: 0.5rem;
  min-width: 32rem;
  /* max-height: 50vh;
  overflow: auto; */
}
.todo-box.dashboard-todo {
  min-width: 0;
  max-width: none;
}
.todo-list-wrapper {
  min-width: 32rem;
  padding: 0.5rem 0rem;
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.todo-list-wrapper.dashboard-todo {
  min-width: 0;
  max-width: none;
}
.todo-list {
  margin: 0.35rem 0;
  border-radius: 0.35rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.5rem;
  color: #fff;
}
.rounded-cont {
  border-radius: 3rem;
}
.task-container {
  max-height: 15rem;
  overflow: auto;
}
.todo-task,
.todo-task-non-active {
  font-size: 1.4rem;
  width: 80%;
  position: relative;
  transition: 0.3s ease;
}
.dd-icon::before {
  margin-right: 0.5rem;
  content: "\f0c9";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: small;
  color: #fff;
  vertical-align: middle;
  cursor: -webkit-grab;
  cursor: grab;
}
.todo-actions {
  display: flex;
  justify-content: flex-end;
  width: 20%;
  font-size: 1rem;
}
.todo-actions button {
  background: transparent !important;
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}
.todo-actions .success-btn:hover {
  color: yellowgreen;
}
.todo-actions .danger-btn:hover {
  color: tomato;
}
@media only screen and (max-width: 800px) {
  .todo-list-wrapper,
  .todo-box {
    min-width: 18rem;
    width: 100%;
    max-width: 26rem;
    padding: 0 0.5rem;
  }
  .todo-task {
    font-size: 1.2rem;
  }
  .todo-task-non-active {
    font-size: 1.2rem;
  }
  .todo-actions {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 340px) {
  .todo-list-wrapper,
  .todo-box {
    min-width: 0;
    width: 100%;
    padding: 0 0.5rem;
  }
  .todo-task {
    font-size: 1rem;
  }
  .todo-task-non-active {
    font-size: 1rem;
  }
  .todo-actions {
    font-size: 0.6rem;
  }
}

.styled-input {
  width: 100%;
  border: none;
  border-radius: 0.5rem;
  text-align: right;
  padding: 0 0.8rem;
}
.dark {
  background-color: rgb(51, 51, 51) !important;
  color: #fff;
}
.light {
  background-color: #fff;
  color: rgb(51, 51, 51);
}
.lg {
  font-size: 1.5rem;
  height: 3rem;
}
.sm {
  font-size: 1.2rem;
  height: 2rem;
}
.styled-label {
  font-weight: 300;
  position: relative;
  border-radius: 0.3rem;
  padding: 0 0.2rem;
  z-index: 99;
}
.styled-label.dark {
  background-color: rgba(51, 51, 51, 1) !important;
  color: rgb(255, 255, 255);
}
.styled-label.light {
  background-color: rgb(51, 51, 51) !important;
  color: #fff;
}
.styled-label.lg {
  top: 0.5rem;
  padding: 0 0.4rem;
  font-size: 0.9rem;
}
.styled-label.sm {
  top: 0.3rem;
  left: 0.4rem;
  font-size: 0.8rem;
}
.styled-input-wrapper {
  padding: 0 0.2rem;
}

.MuiDialog-paper {
  background-color: transparent !important;
  border-radius: 0.75rem !important;
}
.task-dialog {
  min-width: 32rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
}
@media only screen and (max-width: 800px) {
  .task-dialog {
    min-width: 19rem;
  }
}
@media only screen and (max-width: 320px) {
  .task-dialog {
    min-width: 100%;
  }
}
#task-title {
  color: #fff;
  font-size: 1.5rem;
  padding: 0.25rem 0.5rem;
}
.text-left {
  text-align: left !important;
}
.MuiAutocomplete-root {
  width: 100% !important;
}
.MuiInput-root {
  color: #fff !important;
  height: 100% !important;
}
.MuiInput-root::before {
  border-bottom: none !important;
}
.MuiInput-root::after {
  border-bottom: none !important;
}

.MuiDialog-paper {
  background-color: transparent !important;
  border-radius: 0.75rem !important;
}
.task-dialog {
  min-width: 32rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
}
@media only screen and (max-width: 800px) {
  .task-dialog {
    min-width: 19rem;
  }
}
@media only screen and (max-width: 320px) {
  .task-dialog {
    min-width: 100%;
  }
}
#task-title {
  color: #fff;
  font-size: 1.5rem;
  padding: 0.25rem 0.5rem;
}
.text-left {
  text-align: left !important;
}
.MuiAutocomplete-root {
  width: 100% !important;
}
.MuiInput-root {
  color: #fff !important;
  height: 100% !important;
}
.MuiInput-root::before {
  border-bottom: none !important;
}
.MuiInput-root::after {
  border-bottom: none !important;
}

.preset-card-wrapper {
  width: 100%;
  padding: 0 0.2rem;
  background-color: rgba(51, 51, 51, 1);
  border-radius: 0.75rem;
  margin: 0.25rem 0;
  overflow: hidden;
}
.preset-card-header {
  width: 100%;
  padding: 0 0.5rem;
  border-radius: 0.4rem;
  border-bottom-right-radius: 0;
  position: relative;
  top: 0.55rem;
  display: flex;
  justify-content: space-between;
}
.preset-card-header input {
  width: 30%;
  border: none;
  background-color: transparent;
  color: #fff;
}
.preset-card-header input:focus-visible {
  outline: none;
}
.preset-card {
  padding: 0.8rem 0.5rem 0.2rem 0.5rem;
  background-color: rgba(51, 51, 51, 1);
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  height: 0;
  transition: 0.3s ease;
}
.preset-card.open {
  height: 4.5rem;
}
.preset-card-input-group {
  display: flex;
}
.button-conatiner {
  margin-top: 0.3rem;
  display: flex;
  justify-content: space-between;
}
.save-btn,
.load-btn {
  border: none;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 0.25rem 0;
}
.save-btn {
  width: 25%;
}
.load-btn {
  width: 70%;
}
.save-btn span,
.load-btn span {
  margin-left: 0.1rem;
}
.preset-button {
  border: none;
  background: transparent;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 0.25rem;
}
.preset-button:last-of-type {
  margin-right: 0;
}
.preset-buttons-group {
  display: flex;
}
.preset-button i {
  transition: 0.3s ease;
  color: #fff;
}
.preset-button.open i {
  transform: rotateZ(-180deg);
}
.preset-button.check {
  background: #fff;
  opacity: 0;
  visibility: none;
  transition: 0.3s ease;
}
.preset-button.check.show {
  opacity: 1;
  visibility: visible;
}
.preset-button.check i {
  color: rgb(14, 173, 0);
}

.bg-card-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.bg-card {
  width: 25%;
  padding: 0.25rem;
}
.bg-card.active {
  border: 2px solid #fff;
  border-radius: 0.5rem;
}
.bg-img {
  width: 100%;
  height: 4.5rem;
  border-radius: 0.5rem;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0.35rem;
}
.bg-img i {
  color: #fff;
  font-size: small;
}
.audio-wrapper {
  margin-top: 0.75rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  border-radius: 0.5rem;
  background: rgb(51, 51, 51);
}
.audio-icon {
  width: 5%;
  color: #fff;
  cursor: pointer;
}
.audio-slider {
  width: 75%;
}
.audio-test {
  width: 10%;
}
@media only screen and (max-width: 800px) {
  .audio-slider {
    width: 70%;
  }
  .audio-test {
    width: 15%;
  }
}
.css-187mznn-MuiSlider-root {
  color: #fff !important;
}

#setting-title {
  color: #fff;
  font-size: 2rem;
  padding: 0 0 0.5rem 0.25rem;
}
#setting-sub-title {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.5rem;
  padding: 0.5rem 0 0.25rem 1rem;
}
#customization-sub-title {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.5rem;
  padding: 0.5rem 0 0.25rem 1rem;
}
.setting-box {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
.MuiDialog-paper {
  background-color: transparent !important;
  border-radius: 0.75rem !important;
}
.timer-input-group {
  display: flex;
  justify-content: space-around;
}
.timer-input {
  width: 25%;
  transition: 0.3s ease;
  overflow: hidden;
}
.preset-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  overflow-x: auto;
  margin-top: 0.5rem;
}
.misc-settings {
  margin: 0.2rem 0;
}
.misc-wrapper {
  width: 100%;
  padding: 0.25rem;
}
.misc {
  background: rgb(51, 51, 51);
  border-radius: 0.5rem;
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.misc span {
  color: #fff;
  font-weight: 300;
}
.misc span.small {
  font-size: 0.85rem;
}
.MuiSwitch-track {
  background: rgb(255, 255, 255) !important;
}
.Mui-checked + .MuiSwitch-track {
  background: rgb(81, 255, 0) !important;
}
.preset-login-wrapper {
  padding: 0.5rem;
}
.preset-login-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background: rgba(51, 51, 51, 1);
  padding: 0.25rem 0.5rem 0.5rem 0.5rem;
  color: #fff;
}
.preset-login-box div {
  padding: 0.25rem 0 0.75rem 0;
}

.setting-drawer .MuiPaper-root {
  max-height: 70vh;
  overflow: auto;
  background: transparent !important;
  height: 100%;
  display: flex;
  align-items: center;
  max-width: 100vw !important;
}
.setting-box-mobile {
  height: 100%;
  padding: 0.5rem 0.25rem;
  overflow: auto;
  border-radius: 0.5rem 0.5rem 0 0;
}
.timer-input-group-mobile {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.timer-input-mobile {
  width: 50%;
  transition: 0.3s ease;
  overflow: hidden;
}

.notepad-drawer {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 30vw;
  transition: 0.3s ease;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.notepad-title {
  color: #fff;
  font-size: 1.2rem;
  height: 5%;
}
.notepad-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.notepad-wrapper {
  z-index: 120;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  margin: 0.5rem 0;
  height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.notepad-drawer.close {
  transform: translateX(100%);
}
.notepad {
  border-radius: 0.5rem;
  background-color: #fff;
  height: 75%;
}
.notepad.quill {
  height: 100%;
}
.loading-text {
  font-size: small;
  margin-left: 0.5rem;
}
.idea-thought-input {
  width: 100% !important;
  height: 100% !important;
}
.idea-thought-input textarea:focus-visible {
  outline: none !important;
}
#idea-thought-textarea {
  height: 100% !important;
}

.notepad-drawer .MuiPaper-root {
  background: transparent;
  height: 50vh !important;
  overflow: scroll;
}
.notepad-title {
  color: #fff;
  font-size: 1.2rem;
  height: 5%;
}
.notepad-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.notepad-wrapper-mobile {
  z-index: 120;
  padding: 0.5rem 0.5rem;
  margin: 0;
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 0 0 0.5rem 0.5rem;
}
.notepad-drawer.close {
  transform: translateX(100%);
}
.notepad-mobile {
  border-radius: 0.5rem;
  background-color: #fff;
  margin-top: 1rem;
  height: 95%;
  overflow: hidden;
}
.notepad.quill {
  height: 100%;
}
.loading-text {
  font-size: small;
  margin-left: 0.5rem;
}
.idea-thought-input {
  width: 100% !important;
  height: 100% !important;
}
.idea-thought-input textarea {
  margin: 0;
}
.idea-thought-input textarea:focus-visible {
  outline: none !important;
}
#idea-thought-textarea {
  height: 100% !important;
}

.dashboard {
  background-color: rgb(22, 28, 36);
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
}
.dashbaord-content {
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}
.breadcrumbs-container {
  padding: 1rem 2rem;
  height: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.breadcrumbs-container a {
  color: rgba(255, 255, 255, 0.8) !important;
  text-decoration: none !important;
  transition: all 0.3s ease-in-out !important;
}
.neomorph {
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.1);
}
.rotate.neomorph:hover {
  box-shadow: 8px -8px 12px 0 rgba(255, 255, 255, 0.1),
    -12px 12px 16px 0 rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 800px) {
  .breadcrumbs-container * {
    font-size: 0.8rem !important;
  }
  .neomorph {
    box-shadow: none;
  }
}
.breadcrumbs-container a:hover {
  color: #fff !important;
}
.MuiBreadcrumbs-separator {
  color: rgba(255, 255, 255, 0.5);
}
.breadcrumbs-last {
  color: #fff !important;
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.dashboard-index {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.charts-title {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 800;
}
.streak-wrapper {
  padding: 0.8rem 0rem;
}
.streak-wrapper .streaks {
  border-radius: 0rem;
  padding: 0.5rem 0.8rem;
}
.streak-boxes {
  padding: 0.2rem 0.3rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  max-height: 10rem;
  overflow: auto;
}
.streak-boxes.small {
  flex-direction: row;
  justify-content: flex-start;
}

.streak-selected {
  color: #fff;
  display: flex;
  justify-content: space-between;
}
.streak-selected span {
  padding: 0 0.5rem;
}
.streak-selected .MuiInput-root,
.streak-selected .MuiSvgIcon-root {
  color: #fff !important;
}
.streak-selected .MuiInput-root::before {
  border-bottom: none !important;
}
.streak-selected .MuiInput-root::after {
  border-bottom: none !important;
}
.streak-box-wrapper {
  padding: 0.2rem;
}
.streak-box {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.2rem;
  background-color: rgba(255, 255, 255, 0.3);
}
.streak-box.active-focus-1 {
  /*More than 3 hours*/
  background-color: rgba(152, 255, 34, 0.5);
}
.streak-box.active-focus-2 {
  /*More than 1 hour*/
  background-color: rgba(76, 228, 30, 0.5);
}
.streak-box.active-focus-3 {
  /*Less than 1 hour*/
  background-color: rgba(26, 199, 35, 0.5);
}
.streak-box.active-break-1 {
  background-color: rgba(255, 86, 34, 0.5);
}
.streak-box.active-break-2 {
  background-color: rgba(218, 72, 28, 0.5);
}
.streak-box.active-break-3 {
  background-color: rgba(202, 68, 27, 0.5);
}
.streak-box.small {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.1rem;
}
.streak-info-container {
  color: rgba(255, 255, 255, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
}
.streak-info-container i {
  font-size: 0.8rem;
}
.streak-info-container span {
  padding: 0 0.3rem;
}
.tooltip-wrapper {
  padding: 0.25rem 0.25rem;
}
.tooltip-time {
  padding: 0 0.25rem;
}
.tooltip-date {
  font-weight: 800;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}
.tooltip-focus,
.tooltip-break {
  padding: 0.15rem 0.2rem;
}
.tooltip-focus-box {
  padding: 0.1rem 0.3rem;
  font-size: 0.7rem !important;
  font-weight: 700;
  background-color: rgba(152, 255, 34, 0.5);
  border-radius: 0.2rem;
  margin-bottom: 0.1rem;
}
.tooltip-break-box {
  padding: 0.1rem 0.3rem;
  font-size: 0.7rem !important;
  font-weight: 700;
  border-radius: 0.2rem;
  background-color: rgba(255, 86, 34, 0.5);
}
.tooltip-data {
  padding: 0.2rem 0.3rem;
}
.tooltip-data strong {
  padding: 0rem 0.15rem;
}
.radar-chart-wrapper,
.doughnut-chart-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4rem;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.chart-know-more-btn {
  position: absolute;
  bottom: 0rem;
  left: 50%;
  transform: translateX(-50%);
}
.dashboard-active-tasks {
  border-radius: 2.5rem;
}
@media only screen and (max-width: 800px) {
  .line-chart-wrapper {
    padding: 0;
  }
  .streak-wrapper {
    padding: 0.5rem 0;
  }
  .radar-chart-wrapper {
    box-shadow: none;
  }
  .streak-wrapper .streaks {
    border-radius: 0rem;
    padding: 0.5rem 0.8rem;
  }
  .streak-boxes {
    padding: 0.2rem 0.3rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    max-height: 5rem;
    overflow: auto;
  }
  .streak-boxes.small {
    flex-direction: row;
    justify-content: flex-start;
  }
  .streak-box-wrapper {
    padding: 0.1rem;
  }
  .streak-box {
    width: 1rem;
    height: 1rem;
    border-radius: 0.1rem;
  }
  .streak-box.small {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 0.08rem;
  }
}

.line-chart {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.4rem 0.5rem;
  border-radius: 0.6rem;
  color: #fff !important;
}
.doughnut-chart {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.border-muted {
  border-color: rgba(255, 255, 255, 0.1) !important;
}
.line-chart canvas,
.doughnut-chart canvas,
.radar-chart-wrapper canvas {
  width: 100% !important;
  height: 100% !important;
}
.radar-chart-wrapper-pc {
  width: 100%;

  max-width: 35rem !important;
  max-height: 35rem !important;
}
@media only screen and (min-width: 800px) {
  .line-chart {
    padding: 0.4rem 0.7rem;
    height: 100%;
  }
  .line-chart canvas {
    height: 90% !important;
  }
}

.user-time-data {
  padding: 1rem 2rem;
  border-radius: 2rem;
}

.user-time-data-mobile {
  padding: 1.5rem 0.7rem;
  border-radius: 2rem 2rem 0 0;
}

.dashboard-tasks {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.task-containers {
  border-radius: 4rem;
}

.transparent .MuiDrawer-paper {
  background-color: transparent !important;
}
.nav-btn {
  position: fixed;
  z-index: 999999;
  top: 1.5rem;
  height: 3rem;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  padding-right: 1rem;
  justify-content: flex-end;
}
.nav-btn.close {
  left: 0rem;
  width: 5rem;
  border-radius: 0 3rem 3rem 0;
}
.nav-btn.open {
  top: 2rem;
  width: calc(35vw - 2rem);
  max-width: calc(25rem - 2rem);
}
.nav-drawer {
  width: 35vw;
  max-width: 25rem;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 0 1.5rem 1.5rem 0;
}
@media only screen and (max-width: 800px) {
  .nav-drawer {
    width: 85vw;
    max-width: 30rem;
  }
  .nav-btn.open {
    width: calc(85vw - 2rem);
    max-width: calc(30rem - 2rem);
  }
}
@media only screen and (max-width: 350px) {
  .nav-drawer {
    width: 100vw;
    border-radius: 0;
  }
  .nav-btn.open {
    width: calc(100vw - 2rem);
  }
}
.drawer-header {
  position: relative;
  top: 2rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2rem;
}
.nav-title {
  color: #fff;
}
.hover-controler {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background-color: transparent;
  width: 10px;
}
a.nav-link li {
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}
a.nav-link.nav-active li {
  background-color: rgba(255, 255, 255, 0.2);
}
a.nav-link li:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
a.nav-link {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  padding: 0;
}
a.nav-link:hover,
a.nav-link:focus {
  color: #fff;
}
a.nav-link i {
  color: #fff;
}
.MuiListItemIcon-root {
  display: flex;
  justify-content: center;
}
.MuiListItemText-primary {
  font-size: 1.1rem !important;
  font-weight: 800 !important;
}

